import moment from 'moment'
import { startTransition } from 'react'

export function removeEmpty(obj: object) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ''))
}

export function serialize(obj: any) {
  var str = []
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

export function slugify(text: string) {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

export function ssnFormat(v) {
  v = v.slice(0, 11).replace(/-/g, '')
  if (v.length <= 3) {
    return v
  }
  if (v.length > 3 && v.length <= 5) {
    return `${v.slice(0, 3)}-${v.slice(3)}`
  }
  if (v.length > 5) {
    return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`
  }
}

export const phoneFormat = (v) => {
  v = v.slice(0, 20).replace(/\D/g, '')
  if (v.length <= 20) {
    var formattedPhoneNumber = ''
    if (v.length > 0) {
      formattedPhoneNumber = '(' + v.substring(0, 3)
    }

    if (v.length > 3) {
      formattedPhoneNumber += ')-' + v.substring(3, 6)
    }

    if (v.length > 6) {
      formattedPhoneNumber += '-' + v.substring(6, 10)
    }

    if (v.length > 10) {
      formattedPhoneNumber += ' X' + v.substring(10)
    }

    return formattedPhoneNumber
  }
}

function reverseString(str) {
  return (str === '') ? '' : reverseString(str.substr(1)) + str.charAt(0);
}

export const numberDecimalFormat = (v) => {

  let newDigits = ''

  let startingString = v
  let outputString = ''
  let digitCount = 0

  // if (startingString.includes('.')) {
  //   newDigits = startingString.substring(startingString.indexOf('.') + 3)
  //   startingString = startingString.substring(0, startingString.indexOf('.'))
  //   startingString = startingString + newDigits
  //   startingString = startingString.split(',').join('')
  // }

  startingString = startingString.split(',').join('')

  let splitString = startingString.split("")
  splitString.reverse()

  splitString.forEach((item) => {
    if(digitCount == 3){
      outputString = outputString + ',' + item
      digitCount = 0
    }
    else{
      outputString += item
    }

    if(item !== ('.')){
      digitCount++
    }
    else{
      digitCount = 0
    }
  })

  outputString = reverseString(outputString)

  // if (outputString === '') {
  //   return outputString
  // }
  // else {
  //   return outputString = outputString + '.00'
  // }

  return outputString
}

export const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const numberFormatter = new Intl.NumberFormat('en-US')

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatDate(date) {
  if (!date) return null

  const d = date._isAMomentObject ? date.toDate() : date

  if (!isValidDate(d)) return null

  let year = d.getFullYear()
  let month = d.getMonth() + 1
  if (`${month}`.length < 2) month = '0' + month
  let day = d.getDate()
  if (`${day}`.length < 2) day = '0' + day

  let hours = d.getHours()
  if (`${hours}`.length < 2) hours = '0' + hours
  let minutes = d.getMinutes()
  if (`${minutes}`.length < 2) minutes = '0' + minutes
  let seconds = d.getMinutes()
  if (`${seconds}`.length < 2) seconds = '0' + seconds

  return [year, month, day].join('-') + 'T' + [hours, minutes, seconds].join(':')
}

export function formatDateShort(date) {
  if (!date) return null

  const d = date._isAMomentObject ? date.toDate() : date

  if (!isValidDate(d)) return null

  let year = d.getFullYear()
  let month = d.getMonth() + 1
  if (`${month}`.length < 2) month = '0' + month
  let day = d.getDate()
  if (`${day}`.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

export function formattedFullDate(dateString) {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long' };
  const day = date.getDate();
  const month = date.toLocaleDateString('en-US', { month: 'long' });
  const year = date.getFullYear();
  const ordinalSuffix = getOrdinalSuffix(day);

  return `${month} ${day}${ordinalSuffix}, ${year}`;
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

type SortProps = {
  rowA?: object
  rowB?: object
  fieldName?: string
  selector?: Function
  data?: Array<any>
  direction?: string
}

export function createTableSortDefaultFn({ rowA, rowB, fieldName, data, selector, direction }: SortProps) {
  if (data) {
    //? Default sort for tables
    return data.sort((a, b) => {
      const x: any = selector(a) ? selector(a)?.toString()?.trim()?.toLowerCase() || '' : ''
      const y: any = selector(b) ? selector(b)?.toString()?.trim()?.toLowerCase() || '' : ''

      if (x > y) {
        return direction == 'desc' ? -1 : 1
      }
      if (x < y) {
        return direction == 'desc' ? 1 : -1
      }
      return 0
    })
  } else {
    if(Object.keys(rowA).length === 0 && rowA.constructor === Object){
      return 0
    }
    const a = rowA[fieldName]?.trim()?.toLowerCase()
    const b = rowB[fieldName]?.trim()?.toLowerCase()
    if (a == null || a == '') { return 0 }
    if (a > b) { return 1 }
    if (b > a) { return -1 }
    return 0;
  }
}

export function createTableSortDateFn({ rowA, rowB, fieldName, data, selector, direction }: SortProps) {
  if (data) {
    // return data?.sort((a, b) => {
    //   const x: any = selector(a) ? new Date(selector(a)) : 0
    //   const y: any = selector(b) ? new Date(selector(b)) : 0
    //   return direction == 'asc' ? x - y : y - x
    // })
  } else {
    const a = rowA[fieldName] ? new Date(rowA[fieldName]) : 0
    const b = rowB[fieldName] ? new Date(rowB[fieldName]) : 0

    if(Object.keys(rowA).length === 0 && rowA.constructor === Object){
      return 0
    }

    if (a > b) {
      return 1
    }
    if (b > a) {
      return -1
    }
    return 0
  }
}

export function createTableSortNumFn({ rowA, rowB, fieldName, data, selector, direction }: SortProps) {
  if (data) {
    // return data?.sort((a, b) => {
    //   const x = parseInt(selector(a)) || 0
    //   const y = parseInt(selector(b)) || 0
    //   if (x > y) { return 1 }
    //   if (y > x) { return -1 }
    //   return 0;
    // })
  } else {
    const a = rowA[fieldName] ? parseInt(rowA[fieldName]?.toString()?.trim()) || 0 : 0
    const b = rowB[fieldName] ? parseInt(rowB[fieldName]?.toString()?.trim()) || 0 : 0

    if(Object.keys(rowA).length === 0 && rowA.constructor === Object){
      return 0
    }

    if (a > b) {
      return 1
    }
    if (b > a) {
      return -1
    }
    return 0
  }
}

export function sortDefaultFn({ rowA, rowB, fieldName, data, selector, direction }: SortProps) {
  if (data) {
    //? Default sort for tables
    return data.sort((a, b) => {
      const x: any = selector(a) ? selector(a)?.toString()?.trim()?.toLowerCase() || '' : ''
      const y: any = selector(b) ? selector(b)?.toString()?.trim()?.toLowerCase() || '' : ''

      if (x > y) {
        return direction == 'desc' ? -1 : 1
      }
      if (x < y) {
        return direction == 'desc' ? 1 : -1
      }
      return 0
    })
  } else {
    const a = rowA[fieldName]?.trim()?.toLowerCase()
    const b = rowB[fieldName]?.trim()?.toLowerCase()
    if (a == null || a == '') { return 0 }
    if (a > b) { return 1 }
    if (b > a) { return -1 }
    return 0;
  }
}

export function sortDateFn({ rowA, rowB, fieldName, data, selector, direction }: SortProps) {
  if (data) {
    // return data?.sort((a, b) => {
    //   const x: any = selector(a) ? new Date(selector(a)) : 0
    //   const y: any = selector(b) ? new Date(selector(b)) : 0
    //   return direction == 'asc' ? x - y : y - x
    // })
  } else {
    const a = rowA[fieldName] ? new Date(rowA[fieldName]) : 0
    const b = rowB[fieldName] ? new Date(rowB[fieldName]) : 0

    if (a == 0) { return 0 }

    if (a > b) {
      return 1
    }
    if (b > a) {
      return -1
    }
    return 0
  }
}

export function sortNumFn({ rowA, rowB, fieldName, data, selector, direction }: SortProps) {
  if (data) {
    // return data?.sort((a, b) => {
    //   const x = parseInt(selector(a)) || 0
    //   const y = parseInt(selector(b)) || 0
    //   if (x > y) { return 1 }
    //   if (y > x) { return -1 }
    //   return 0;
    // })
  } else {
    const a = rowA[fieldName] ? parseInt(rowA[fieldName]?.toString()?.trim()) || 0 : 0
    const b = rowB[fieldName] ? parseInt(rowB[fieldName]?.toString()?.trim()) || 0 : 0

    if (a == 0) { return 0 }

    if (a > b) {
      return 1
    }
    if (b > a) {
      return -1
    }
    return 0
  }
}

export function sortByDateDesc(elements, fieldname) {
  return elements.sort((a, b) => {
    const aDate = new Date(a[fieldname])
    const bDate = new Date(b[fieldname])
    return bDate.getTime() - aDate.getTime()
  })
}

export function formatPercentage(num) {
  return Number(num / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
}

export function getTodayWithOffset() {
  const offset = new Date().getTimezoneOffset()
  if (offset > 0) return formatDate(moment().subtract(offset, 'minutes'))
  if (offset < 0) return formatDate(moment().add(offset, 'minutes'))
  return formatDate(moment())
}

export function shortDateToFormatedDate(date) {
  date = date.slice(0, -9);
  const dateArr = date.split('-')
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
}
