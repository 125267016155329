export const APP_PERMISSIONS = 'permissions'
export const TRUSTEE_ID = 9
export const OFFICER_IN_CHARGE_ID = 11

export enum RBAC_FUNCTIONALITIES {
  MEMBER_CREATE = 'MEMBER_CREATE',
  MEMBER_EDIT_EVENTS = 'MEMBER_EDIT_EVENTS',
  MEMBER_CLEAR_SEARCH = 'MEMBER_CLEAR_SEARCH',
  MEMBER_COMMENTS = 'MEMBER_COMMENTS',
  MEMBER_CREATED_BY = 'MEMBER_CREATED_BY',
  MEMBER_UPDATED_BY = 'MEMBER_UPDATED_BY',
  ASSIGN_ROLE = 'ASSIGN_ROLE',
  HOME_SHOW_IN_SIDEBAR = 'HOME_SHOW_IN_SIDEBAR',
  HOME_ROLES_SEARCH = 'HOME_ROLES_SEARCH',
  HOME_USERS_SEARCH = 'HOME_USERS_SEARCH',
  HOME_CHANGE_EMAIL = 'HOME_CHANGE_EMAIL',
  HOME_USERS_EDIT_GENERAL_INFO = 'HOME_USERS_EDIT_GENERAL_INFO',
  /* Quarterly Reports Module */
  QUARTERLY_REPORTS_CLEAR_SEARCH = 'QUARTERLY_REPORTS_CLEAR_SEARCH',
  QUARTERLY_REPORTS_SEARCH_SPECIAL_COLUMNS = 'QUARTERLY_REPORTS_SEARCH_SPECIAL_COLUMNS',
  /* Locals Module */
  LOCALS_SEARCH = 'LOCALS_SEARCH',
  LOCALS_OFFICERS_REMOVE = 'LOCALS_OFFICERS_REMOVE',
  LOCALS_OFFICERS_UPDATE = 'LOCALS_OFFICERS_UPDATE',
  LOCALS_OFFICER_RANK_MODIFICATION = 'LOCALS_OFFICER_RANK_MODIFICATION',
  LOCALS_BOND_AMOUNT = 'LOCALS_BOND_AMOUNT',
  LOCALS_EIN = 'LOCALS_EIN',
  LOCALS_DELEGATE_KIT_SENT = 'LOCALS_DELEGATE_KIT_SENT',
  LOCALS_SUPPLIES_RECEIVED = 'LOCALS_SUPPLIES_RECEIVED',
  LOCALS_SUPPLIES_RECEIVED_DATE = 'LOCALS_SUPPLIES_RECEIVED_DATE',
  LOCALS_JURISDICTION = 'LOCALS_JURISDICTION',
  LOCALS_SHORT_JURISDICTION = 'LOCALS_SHORT_JURISDICTION',
  LOCALS_CLASSES = 'LOCALS_CLASSES',
  LOCALS_DISTRICTS = 'LOCALS_DISTRICTS',
  LOCALS_CHARTER_DATE = 'LOCALS_CHARTER_DATE',
  LOCALS_DEFUNCT_DATE = 'LOCALS_DEFUNCT_DATE',
  LOCALS_COMMENT = 'LOCALS_COMMENT',
  LOCALS_CRAFT = 'LOCALS_CRAFT',
  /* Reports Module */
  REPORTS_AUTOMATIC_SEARCH = 'REPORTS_AUTOMATIC_SEARCH',
  REPORTS_SHOW_SEARCH_FORM = 'REPORTS_SHOW_SEARCH_FORM',
}

export enum RBAC_ROLES {
  LOCAL_ADMIN_LABEL = 'Local Administrator',
  LOCAL_ADMIN_ROLE_ID = 9,
  LOCAL_ADMIN = 'LOCALAdministrator',
  LOCAL_CLERK = 'LOCALClerk',
  READ_ONLY_LOCAL = 'ReadOnlyLocal',
  IATSE_UNION_MEMBER = 'IATSEUnionMember',
  IATSEAdministrator = 'IATSEAdministrator',
  FIN_LEVEL_2 = 'Fin-Level_2',
  FIN_LEVEL_3 = 'Fin-Level_3',
  FIN_LEVEL_4 = 'Fin-Level_4',
  FIN_LEVEL_5 = 'Fin-Level_5',
  FINANCE_DATA_ENTRY = 'Finance Data Entry',
  FINANCE_HELP_DESK = 'Finance Help Desk',
  PRES_LEVEL_1 = 'Pres-Level_1',
  PRES_LEVEL_2 = 'Pres-Level_2',
  PRES_LEVEL_3 = 'Pres-Level_3',
  EVENTS_EDIT = 'EventsEdit',
  EVENTS_ADMIN = 'EventsAdmin',
  OFFICIAL_FAMILY = 'Official Family',
  MEMBERSHIP_APPLICATION_ADMIN = 'Membership Application Admin',
  SUPER_ADMIN = 'SuperAdmin',
  ORDERS_PAYMENT = 'Order Payment',
  DISTRICT = 'District',
  DISTRICT_ROLE_ID = 65,
}

export enum RBAC_PERMISSIONS {
  /* Admin Permissions */
  ASSIGN_ROLE = 'ASSIGN_ROLE_TO_USER',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  ORDERS_LOCAL_CRAFTSMODIFY_ROLE = 'MODIFY_ROLE',
  CREATE_ROLE = 'CREATE_ROLE',
  MODIFY_ROLE = 'MODIFY_ROLE',
  CREATE_USER = 'CREATE_USER',
  HOME = 'HOME',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  IMPERSONATE_ADMIN = 'IMPERSONATE_ADMIN',
  UNLOCK_USER = 'UNLOCK_USER',
  REVOKE_USER = 'REVOKE_USER',
  RESET_USER = 'RESET_USER',
  /* Members Permissions */
  MEMBERS = 'MEMBERS',
  MEMBERS_VIEW = 'VIEW_MEMBER',
  MEMBERS_CREATE = 'NEW_MEMBER',
  MEMBERS_UPDATE = 'UPDATE_MEMBER',
  MEMBERS_DELETE = 'DELETE_MEMBER',
  MEMBERS_TRANSFER = 'TRANSFER_MEMBER',
  MEMBERS_CONTRACT_HISTORY = 'VIEW_MEMBER_CONTRACT_HISTORY',
  MEMBERS_AUDIT_LOG_REPORT = 'AUDIT-LOG_REPORT',
  MEMBERS_STATUS_HISTORY = 'MEMBER_STATUS_HISTORY',
  MEMBERS_LOCAL_ADMIT_HISTORY = 'LOCAL_ADMIT_HISTORY',
  MEMBERS_EVENTS = 'MEMBER_EVENTS',
  ADD_MEMBER_EVENT = 'ADD_MEMBER_EVENT',
  MEMBERS_EDIT_EVENTS = 'EDIT_MEMBER_EVENT',
  MEMBERS_VIEW_EVENTS = 'VIEW_MEMBER_EVENT',
  MEMBERS_TTF_TITLES = 'MEMBER_TTF_TITLES',
  MEMBERS_ACTIONS = 'MEMBER_ACTIONS',
  // MEMBERSHIP_APPLICATIONS = 'MEMBERSHIPAPPLICATIONS',
  // MEMBERSHIP_REQUESTS = 'MEMBERSHIPREQUESTS',
  MEMBERSHIP_APPLICATION = 'MEMBERSHIP_APPLICATION',
  MEMBERSHIP_REQUEST = 'MEMBERSHIP_REQUEST',
  MEMBER_GROUP_MAINTENANCE = 'MEMBER_GROUP_MAINTENANCE',
  GROUP_MEMBERS_MAINTENANCE = 'GROUP_MEMBERS_MAINTENANCE',
  MEMBER_ADDRESS_FILE = 'MEMBER_ADDRESS_FILE',
  MULTIPLE_MEMBER_SEARCH = 'MULTIPLE_MEMBER_SEARCH',
  UPDATE_MEMBER_50YR_SCROLL = 'UPDATE_MEMBER_50YR_SCROLL',
  MEMBER_BY_DISTRICT = 'MEMBER_BY_DISTRICT',
  DECEASED_MEMBERS = 'DECEASED_MEMBERS',
  ALLOCATE_REPLACEMENT_STAMPS = 'ALLOCATE_REPLACEMENT_STAMPS',
  /* Orders Permissions */
  ORDERS = 'ORDERS',
  ORDERS_CREATE = 'NEW_ORDER',
  ORDERS_UPDATE = 'UPDATE_ORDER',
  ORDERS_VIEW = 'VIEW_ORDER',
  ORDERS_ITEMS = 'ITEMS',
  ORDERS_ADD_ITEM = 'ADD_ITEM',
  ORDERS_ADD_STAMP_ITEM = 'ADD_STAMP_ITEM',
  ORDERS_UPDATE_ITEM = 'UPDATE_ITEM',
  ORDERS_VIEW_ITEM = 'VIEW_ITEM',
  ORDERS_VIEW_STAMP_ITEM = 'VIEW_STAMP_ITEM',
  ORDERS_UPDATE_STAMP_ITEM = 'UPDATE_STAMP_ITEM',
  TRANSACTION_CHECK = 'TRANSACTION_CHECK',
  ORDERS_LOCAL_CRAFTS = 'LOCAL_CRAFTS',
  ORDERS_ADD_LOCAL_CRAFTS = 'ADD_CRAFT',
  /* Locals Permissions */
  ADDRESS_UPLOAD = 'ADDRESS_UPLOAD',
  LOCALS = 'LOCALS',
  LOCALS_VIEW = 'VIEW_LOCAL',
  LOCAL_INFO = 'LOCAL_INFO',
  LOCALS_COMMITEE_REPORT = 'CANADIAN_LOCAL_COMMITTEE_REPORT',
  OFFICERS_UPDATE_REPORT = 'OFFICERS_UPDATE_REPORT',
  LOCALS_SUPPLIES_SENT_MAINTENANCE = 'LOCAL_YEAR_END_SUPPLIES',
  NEW_LOCAL = 'NEW_LOCAL',
  VIEW_LOCAL = 'VIEW_LOCAL',
  UPDATE_LOCAL = 'UPDATE_LOCAL',
  OFFICER_MAINTENANCE = 'OFFICER_MAINTENANCE',
  OFFICERS_HISTORY = 'OFFICERS_HISTORY',
  CANADIAN_COMMITTEES = 'CANADIAN_COMMITTEES',
  UPDATE_OFFICER = 'UPDATE_OFFICER',
  MERGE_LOCALS = 'MERGE_LOCALS',
  /* Shows Permissions */
  SHOWS = 'SHOWS',
  SHOWS_CREATE = 'NEW_SHOW',
  SHOWS_VIEW = 'VIEW_SHOW',
  SHOWS_UPDATE = 'UPDATE_SHOW',
  PINK_CONTRACT_LIST = 'PINK_CONTRACT_LIST',
  PINK_CONTRACT_VIEW_UPDATE = 'VIEW_UPDATE_PINK_CONTRACT',
  PINK_CONTRACT_PERMANENT_SUBSTITUTE_CONTRACT = 'PERMANENT/SUBSTITUTE_CONTRACT',
  PINK_CONTRACT_POSITIONS = 'PINK_CONTRACT_POSITIONS',
  PINK_CONTRACT_POSITIONS_CREATE = 'ADD_POSITION',
  /* MEMBER CARD MAINTENANCE Permissions */
  MEMBER_CARD_MAINT = 'MEMBER_CARD_MAINT',
  MEMBER_CARDS_ADD_RANGE = 'MEMBER_CARDS_ADD_RANGE',
  MEMBER_CARD_VIEW = 'MEMBER_CARD_VIEW',
  MEMBER_CARD_EDIT = 'MEMBER_CARD_EDIT',
  /* REPORTS Permissions */
  REPORTS = 'REPORTS',
  LOCAL_MEMBERSHIP_ROSTER = 'LOCAL_MEMBERSHIP_ROSTER',
  STAMP_PURCHASE_QR_REQUIREMENTS = 'STAMP_PURCHASE_&_QR_REQUIREMENTS',
  TTF_TITLES_AD_HOC_REPORT = 'TTF_TITLES_AD-HOC_REPORT',
  TTF_MEMBER_STATUS_REPORT = 'TTF_MEMBER_STATUS_REPORT',
  IL_NUMBER_SEARCH_REPORT = 'IL_NUMBER_SEARCH_REPORT',
  DAILY_CHECK_REGISTER = 'DAILY_CHECK_REGISTER',
  SUMMARY_CASH_REGISTER_BY_DAY = 'SUMMARY_CASH_REGISTER_BY_DAY',
  SUMMARY_CASH_REGISTER_BY_MONTH = 'SUMMARY_CASH_REGISTER_BY_MONTH',
  LOCALS_LIST_OF_CHECKS = "LOCAL\'S_LIST_OF_CHECKS",
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  TRANSACTION_REPORT = 'TRANSACTION_REPORT',
  QUARTERLY_REPORT = 'QUARTERLY_REPORT',
  SCROLLS_REPORT = 'SCROLLS_REPORT',
  STAMP_PURCHASE_TRACKING_INFO = 'STAMP_PURCHASE_TRACKING_INFO',
  STAMP_PURCHASE_TRACKING_BY_LOCAL = 'STAMP_PURCHASE_TRACKING_BY_LOCAL',
  LOCAL_CRAFTS_BY_DISTRICT = 'LOCAL_CRAFTS_BY_DISTRICT',
  LOCAL_JURISDICTION_BY_DISTRICT = 'LOCAL_JURISDICTION_BY_DISTRICT',
  LOCAL_MEMBERSHIP_BY_CRAFT = 'LOCAL_MEMBERSHIP_BY_CRAFT',
  LOCAL_OFFICERS = 'LOCAL_OFFICERS',
  LOCAL_YEAR_END_SUPPLIES = 'LOCAL_YEAR_END_SUPPLIES',
  STAMP_PURCHASE_AND_QR_INFO = 'STAMP_PURCHASE_&_QR_INFO',
  STAMP_PURCHASE_AND_QR_REQUIREMENTS = 'STAMP_PURCHASE_&_QR_REQUIREMENTS',
  LOCALS_DELINQUENT_IN_STAMPS = 'LOCALS_DELINQUENT_IN_STAMPS',
  LOCAL_OFFICERS_MAILING_LABELS = 'LOCAL-OFFICER\'S_MAILING_LABELS',
  LOCALS_WITH_OUTSTANDING_QRS = 'LOCALS_WITH_OUTSTANDING_QRS',
  LOCALS_ELIGIBLE_FOR_DELEGATE_KIT = 'LOCALS_ELIGIBLE_FOR_DELEGATE_KIT',
  UNPUBLISHED_SHOW_REPORT = 'UNPUBLISHED_SHOW_REPORT',
  QUARTERLY_REPORT_WARNING_LETTER = 'QUARTERLY_REPORT_WARNING_LETTER',
  YEAR_END_SUPPLIES_LETTER = 'YEAR_END_SUPPLIES_LETTER',
  QRS_SUBMITTED_ONLINE = 'QRS_SUBMITTED_ONLINE',
  OFFICER_TERM_END_REPORT = 'OFFICER_TERM_END_REPORT',
  MEMBER_EVENTS_AD_HOC_REPORT = 'MEMBER_EVENTS_AD-HOC_REPORT',
  MEMBER_ACTIONS_AD_HOC_REPORT = 'MEMBER_ACTIONS_AD-HOC_REPORT',
  PER_CAPITA_WAIVER_ORDERS_REPORT = 'PER_CAPITA_WAIVER_ORDERS_REPORT',
  DAILY_TRANSACTION_REPORT = 'DAILY_TRANSACTION_REPORT',
  LOCAL_SECRETARIES_AND_BUSINESS_AGENTS = 'LOCAL_SECRETARIES_AND_BUSINESS_AGENTS',
  /* QUARTERLY REPORTS Permissions */
  QUARTERLY_REPORTS = 'QUARTERLY_REPORTS',
  VIEW_QUARTERLY_REPORT = 'VIEW_QUARTERLY_REPORT',
  EDIT_QUARTERLY_REPORT = 'EDIT_QUARTERLY_REPORT',
  SUCCESSFULLY_SUBMITTED_REPORT = 'SUCCESSFULLY_SUBMITTED_REPORT',
  /* FORMS_AND_BOOKLETS Permissions */
  FORMS_AND_BOOKLETS = 'FORMS_AND_BOOKLETS',
  ORDER_FORMS_AND_BOOKLETS = 'ORDER_FORMS_AND_BOOKLETS',
  /* OTHER Permissions */
  OTHER = 'OTHER',
  UPLOAD_USPS_FILE = 'UPLOAD_USPS_FILE',
  PROCESS_USPS_FILE = 'PROCESS_USPS_FILE',
  VIEW_COMPS_MEMBER = 'VIEW_COMPS_MEMBER',
  COMPS_MEMBERS = 'COMPS_MEMBERS',
  UPDATE_COMPS_MEMBER = 'UPDATE_COMPS_MEMBER',
  ADD = 'ADD',
  PROCESS_REGISTRATION_IMPORT = 'PROCESS_REGISTRATION_IMPORT',
  AUDIT_LOG = 'AUDIT_LOG',
  /* VISA TRACKING Permissions */
  VISA_TRACKING = 'VISA_TRACKING',
  ADD_VISA_REQUEST = 'ADD_VISA_REQUEST',
  EDIT_VISA_REQUEST = 'EDIT_VISA_REQUEST',
  MANAGE_BENEFICIARIES = 'MANAGE_BENEFICIARIES',
  ADD_BENEFICIARY = 'ADD_BENEFICIARY',
  EDIT_BENEFICIARY = 'EDIT_BENEFICIARY',
  ADD_FILING_AGENCY = 'ADD_FILING_AGENCY',
  EDIT_FILING_AGENCY = 'EDIT_FILING_AGENCY',
  MANAGE_FILING_AGENCIES = 'MANAGE_FILING_AGENCIES',
  MANAGE_PETITIONERS = 'MANAGE_PETITIONERS',
  ADD_PETITIONER = 'ADD_PETITIONER',
  EDIT_PETITIONER = 'EDIT_PETITIONER',
  MANAGE_ATTORNEYS = 'MANAGE_ATTORNEYS',
  MANAGE_INS_AGENCIES = 'MANAGE_INS_AGENCIES',
  ADD_INS_AGENCY = 'ADD_INS_AGENCY',
  EDIT_INS_AGENCY = 'EDIT_INS_AGENCY',
  MANAGE_ENGAGEMENTS = 'MANAGE_ENGAGEMENTS',
  ADD_ENGAGEMENT = 'ADD_ENGAGEMENT',
  EDIT_ENGAGEMENT = 'EDIT_ENGAGEMENT',
  VISA_BY_ENGAGEMENT_REPORT = 'VISA_BY_ENGAGEMENT_REPORT',
  /* COMPANIES Permissions */
  COMPANIES = 'COMPANIES',
  DELETE_COMPANY = 'DELETE_COMPANY',
  COMPANY_ALERT = 'COMPANY_ALERT',
  'VIEW/UPDATE_COMPANY' = 'VIEW/UPDATE_COMPANY',
  ADD_COMPANY = 'ADD_COMPANY',
  /* VENUES Permissions */
  VENUES = 'VENUES',
  ADD_VENUE = 'ADD_VENUE',
  VIEW_VENUE = 'VIEW_VENUE',
  UPDATE_VENUE = 'UPDATE_VENUE',
  /* TEMPLATE_MGMT Permissions */
  TEMPLATE_MGMT = 'TEMPLATE_MGMT',
  ADD_TEMPLATE = 'ADD_TEMPLATE',
  VIEW_TEMPLATE = 'VIEW_TEMPLATE',
  /* GENERAL_MANAGERS Permissions */
  GENERAL_MANAGERS = 'GENERAL_MANAGERS',
  ADD_GENERAL_MANAGER = 'ADD_GENERAL_MANAGER',
  VIEW_GENERAL_MANAGER = 'VIEW_GENERAL_MANAGER',
  EDIT_GENERAL_MANAGERS = 'EDIT_GENERAL_MANAGERS',
  ADD_A_SHOW = 'ADD_A_SHOW',
  /* LOCATIONS Permissions */
  LOCATIONS = 'LOCATIONS',
  ADD_LOCATION = 'ADD_LOCATION',
  'VIEW/UPDATE_LOCATION' = 'VIEW/UPDATE_LOCATION',
  /* CONTACTS Permissions */
  CONTACTS = 'CONTACTS',
  ADD_CONTACT = 'ADD_CONTACT',
  'VIEW/UPDATE_CONTACT' = 'VIEW/UPDATE_CONTACT',
  DELETE_CONTACT = 'DELETE_CONTACT',
  /* AGREEMENTS Permissions */
  AGREEMENTS = 'AGREEMENTS',
  ADD_AGREEMENT = 'ADD_AGREEMENT',
  VIEW_AGREEMENT = 'VIEW_AGREEMENT',
  UPDATE_AGREEMENT = 'UPDATE_AGREEMENT',
  DELETE_AGREEMENT = 'DELETE_AGREEMENT',
  /* AGREEMENT TYPES Permissions */
  AGREEMENT_TYPES = 'AGREEMENT_TYPES',
  ADD_AGREEMENT_TYPE = 'ADD_AGREEMENT_TYPE',
  'VIEW/UPDATE_AGREEMENT_TYPE' = 'VIEW/UPDATE_AGREEMENT_TYPE',
  /* CONTRACT_PROVISIONS Permissions */
  CONTRACT_PROVISIONS = 'CONTRACT_PROVISIONS',
  /* CONTRACTS_REPORTS Permissions */
  CONTRACTS_REPORTS = 'CONTRACTS_REPORTS',
  AGREEMENTS_AD_HOC_REPORT = 'AGREEMENTS_AD_HOC_REPORT',
  COMPANIES_AD_HOC_REPORT = 'COMPANIES_AD_HOC_REPORT',
  CONTACTS_AD_HOC_REPORT = 'CONTACTS_AD_HOC_REPORT',
  GRIEVANCES_AD_HOC_REPORT = 'GRIEVANCES_AD_HOC_REPORT',
  AGREEMENTS_MAILING = 'AGREEMENTS_MAILING',
  EXPIRING_CONTRACTS_AD_HOC_REPORT = 'EXPIRING_CONTRACTS_AD_HOC_REPORT',
  RUN = 'RUN',
  CLDP_REPORT = 'CLDP_REPORT',
  MONTHLY_REPORTS = 'MONTHLY_REPORTS',
  FMCS_LETTERS = 'FMCS_LETTERS',
  ACTIVE_PRODUCTIONS_LIST = 'ACTIVE_PRODUCTIONS_LIST',
  WEEKLY_MAILING = 'WEEKLY_MAILING',
  /* GRIEVANCES Permissions */
  GRIEVANCES = 'GRIEVANCES',
  ADD_GRIEVANCE = 'ADD_GRIEVANCE',
  VIEW_GRIEVANCE = 'VIEW_GRIEVANCE',
  UPDATE_GRIEVANCE = 'UPDATE_GRIEVANCE',
  DELETE_GRIEVANCE = 'DELETE_GRIEVANCE',
  /* SAFETY APP Permissions */
  SAFETY_APP = 'SAFETY_APP',
  MAIN_MENU_ITEMS = 'MAIN_MENU_ITEMS',
  SORT_ORDER = 'SORT_ORDER',
  SUB_MENU_ITEMS = 'SUB_MENU_ITEMS',
  CONTENT = 'CONTENT',
  SAFETY_NUMBERS = 'SAFETY_NUMBERS',
  EMAILS = 'EMAILS',
  SUBMITTED_HAZARD_FORMS = 'SUBMITTED_HAZARD_FORMS',
  /* Contracts Permissions */
  CONTRACTS = 'CONTRACTS',
  /* General Search */
  GENERAL_SEARCH = 'GENERAL_SEARCH',
  /* Traveling Mem. Rpts. */
  TRAVELING_MEM_RPTS = 'TRAVELING_MEM_RPTS',
  TRAVELING_MEMBERS_BY_LOCAL = 'TRAVELING_MEMBERS_BY_LOCAL',
  TRAVELING_MEMBERS_CONTRACT_HISTORY = 'TRAVELING_MEMBERS_CONTRACT_HISTORY',
  TRAVELING_MEMBERS_BY_CRAFT = 'TRAVELING_MEMBERS_BY_CRAFT',
  TRAVELING_MEMBERS_BY_DEPARTMENT = 'TRAVELING_MEMBERS_BY_DEPARTMENT',
  TRAVELING_MEMBERS_PAY_SCALE = 'TRAVELING_MEMBERS_PAY_SCALE',
  SHOW_LIST_BY_GENERAL_MANAGER = 'SHOW_LIST_BY_GENERAL_MANAGER',
  SHOW_LIST_BY_STATUS = 'SHOW_LIST_BY_STATUS',
  TYPE_OF_SHOW = 'TYPE_OF_SHOW',
  TYPE_OF_SALARY = 'TYPE_OF_SALARY',
  LOCAL_REPORT = 'LOCAL_REPORT',
  PINK_CONTRACT_REPORT = 'PINK_CONTRACT_REPORT',
  /* Convention */
  CONVENTION = 'CONVENTION',
  VIEW_DELEGATE = 'VIEW_DELEGATE',
  UPDATE_DELEGATE = 'UPDATE_DELEGATE',
  'DELEGATE_&_VOTE_STATS' = 'DELEGATE_&_VOTE_STATS',
  DELEGATES_BY_LOCAL = 'DELEGATES_BY_LOCAL',
  DELEGATES_BY_DISTRICT = 'DELEGATES_BY_DISTRICT',
  CHARGES_TO_MASTER_ACCOUNT = 'CHARGES_TO_MASTER_ACCOUNT',
  TRAVEL_REIMBURSEMENTS = 'TRAVEL_REIMBURSEMENTS',
  TRAVEL_EXPENSE_SUMMARY = 'TRAVEL_EXPENSE_SUMMARY',
  PRINT_DELEGATE_BADGES = 'PRINT_DELEGATE_BADGES',
  DELEGATE_CHECKS = 'DELEGATE_CHECKS',
  'DELEGATES_W/_NO_TRAVEL_EXPENSES' = 'DELEGATES_W/_NO_TRAVEL_EXPENSES',
  SET_CONVENTION_PER_DIEM = 'SET_CONVENTION_PER_DIEM',
  PROOFREAD_SHORT_JURISDICTIONS = 'PROOFREAD_SHORT_JURISDICTIONS',
  'PROOFREAD_MAX_DELEGATES_&_VOTES' = 'PROOFREAD_MAX_DELEGATES_&_VOTES',
  COMMITTEE_MAINTENANCE = 'COMMITTEE_MAINTENANCE',
  REVIEW_DELEGATE_REQUESTS = 'REVIEW_DELEGATE_REQUESTS',
  /* Press Agents */
  PRESS_AGENTS = 'PRESS_AGENTS',
  ADD_PRESS_AGENT = 'ADD_PRESS_AGENT',
  VIEW_PRESS_AGENT = 'VIEW_PRESS_AGENT',
  EDIT_PRESS_AGENT = 'EDIT_PRESS_AGENT',
  /* Weekly Reports */
  WEEKLY_REPORTS = 'WEEKLY_REPORTS',
  /* TTF Titles */
  TTF_TITLES = 'TTF_TITLES',
  ADD_TTF_TITLE = 'ADD_TTF_TITLE',
  EDIT_TTF_TITLE = 'EDIT_TTF_TITLE',
  VIEW_TTF_TITLE = 'VIEW_TTF_TITLE',
  UPLOAD_TTF_FILES = 'UPLOAD_TTF_FILES',
  /* Organizing */
  ORGANIZING = 'ORGANIZING',
  ORGANIZE_AUTHORIZATION_SUBMISSIONS = 'ORGANIZE_AUTHORIZATION_SUBMISSIONS',
  VIEW_ORGANIZING_CAMPAIGN = 'VIEW_ORGANIZING_CAMPAIGN',
  ADD_ORGANIZING_CAMPAIGN = 'ADD_ORGANIZING_CAMPAIGN',
  SUBMIT_MANUAL_ORGANIZING_CARD = 'SUBMIT_MANUAL_ORGANIZING_CARD',
  CAMPAIGN_CREW_LIST = 'CAMPAIGN_CREW_LIST',
  'VIEW/EDIT_ARTIST' = 'VIEW/EDIT_ARTIST',
  SEND_BULK_EMAIL = 'SEND_BULK_EMAIL',
  IMPORT_CREW_LIST = 'IMPORT_CREW_LIST',
  ADD_ARTIST = 'ADD_ARTIST',
  /* TTF Contacts */
  TTF_CONTACTS = 'TTF_CONTACTS',
  ADD_TTF_CONTACT = 'ADD_TTF_CONTACT',
  VIEW_TTF_CONTACT = 'VIEW_TTF_CONTACT',
  UPDATE_TTF_CONTACT = 'UPDATE_TTF_CONTACT',
  CONTACT_TTF_TITLES = 'CONTACT_TTF_TITLES',
  MIGRATE_TTF_CONTACTS = 'MIGRATE_TTF_CONTACTS',
  PRIMARY_JOB_CLASSIFICATIONS = 'PRIMARY_JOB_CLASSIFICATIONS',
  /* Event Contacts */
  EVENT_CONTACTS = 'EVENT_CONTACTS',
  ADD_EVENT_CONTACT = 'ADD_EVENT_CONTACT',
  VIEW_EVENT_CONTACT = 'VIEW_EVENT_CONTACT',
  UPDATE_EVENT_CONTACT = 'UPDATE_EVENT_CONTACT',
  EVENT_CONTACT_EVENTS = 'EVENT_CONTACT_EVENTS',
}
