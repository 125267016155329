import { agent } from './api.service'
import { Cookies as ReactCookies } from 'react-cookie'

const cookies = new ReactCookies()
const cookie = cookies.get('iatseToken')

const endpoint = 'iatse_orders_services/orders'
const naiveEndpoint = 'iatse_orders_services'
const stampsEndpoint = 'iatse_orders_services/stamps'
const craftEndpoint = 'iatse_orders_services/Crafts'

// ▼ Orders ▼

export async function getOrders() {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}?$top=500`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getOrderByParams(
  params: any,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false,
  sort: { [key: string]: any } = {}
) {
  const newParams = {
    checkNumber: params?.checkNumber ?? '',
    localNumber: params?.localNumber,
    orderNumber: parseInt(params?.orderNumber) || 0,
    statusId: parseInt(params?.statusId) || 0,
    transactionTypeId: parseInt(params?.transactionTypeId) || 0,
    specialMemberTypeId: 0,
    specialMemberSubgroup: 0,
    iatseAccount: 0,
    stampOnly: 0,
    searchByUser: '',
    includeCreated: 0,
  }

  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderSearch`
    : `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderSearch?${
        Object.keys(sort)?.length
          ? `&$orderBy=${sort.sortBy} ${sort.sortDir.toUpperCase()}`
          : '$orderby=orderNumber desc'
      }`
  let response
  await agent
    .post(URL)
    .send(newParams)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function searchOrder(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/ViewOrder/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function creatadd(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/OrderCreate`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateOrder(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}/queries/OrderUpdate`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getSearchItemsList() {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_services/orderSearch/getItems`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// export async function getCreditAvailableListByRegularLocal(local: string) {
//   const body = {
//     localID: null,
//     localNum: `${local}`,
//   }
//   const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/ViewOrders/queries/CreditAvailableListByRegularLocal`
//   let response
//   await agent
//     .post(URL)
//     .send(body)
//  .set('Authorization', 'Bearer ' + cookie)
//     .then((res: Response) => {
//       response = { ...res, data: res?.body }
//     })
//     .catch((error: any) => {
//       response = { ...error.response, data: error.response?.body }
//     })
//   return response
// }

// export async function getPerCapWaiverCredits(local: any) {
//   const body = {
//     localNum: `${local}`,
//     orderTypeId: 0,
//     quarterId: 0,
//     memberIdString: '',
//     memberSubgroupId: 0,
//   }
//   const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/GetListPerCapWaiverCredits`
//   let response
//   await agent
//     .post(URL)
//     .send(body)
//  .set('Authorization', 'Bearer ' + cookie)
//     .then((res: Response) => {
//       response = { ...res, data: res?.body }
//     })
//     .catch((error: any) => {
//       response = { ...error.response, data: error.response?.body }
//     })
//   return response
// }

// export async function deletePerCapWaiverCredit(id: string) {
//   const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/RemovePerCapWaiverCredits/${id}`
//   let response
//   await agent
//     .delete(URL)
//  .set('Authorization', 'Bearer ' + cookie)
//     .then((res: Response) => {
//       response = { ...res, data: res?.body }
//     })
//     .catch((error: any) => {
//       response = { ...error.response, data: error.response?.body }
//     })
//   return response
// }

// export async function updatePerCapWaiverCredit(credit: object) {
//   const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/ManagePerCapWaiverCredits`
//   let response
//   await agent
//     .post(URL)
//     .send(credit)
//  .set('Authorization', 'Bearer ' + cookie)
//     .then((res: Response) => {
//       response = { ...res, data: res?.body }
//     })
//     .catch((error: any) => {
//       response = { ...error.response, data: error.response?.body }
//     })
//   return response
// }

// export async function addOrder(local: any) {
//   const body = {
//     localNumber: local,
//     orderTypeId: 1,
//     orderCategoryId: 1,
//     memberId: '',
//     user: '',
//   }
//   const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/AddNewOrder`
//   let response
//   await agent
//     .post(URL)
//     .send(body)
//  .set('Authorization', 'Bearer ' + cookie)
//     .then((res: Response) => {
//       response = { ...res, data: res?.body }
//     })
//     .catch((error: any) => {
//       response = { ...error.response, data: error.response?.body }
//     })
//   return response
// }

// ▼ Stamps ▼

export async function getReplacementStampOrderDetails(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/GetReplacementStampOrderDetails/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getAllStampsReplacement(data: { [key: string]: any }, sort: { [key: string]: any } = {}) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/Search/${data.localNum}?${
    Object.keys(sort)?.length ? `&$orderBy=${sort.sortBy} ${sort.sortDir.toUpperCase()}` : ''
  }`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getStampReplacement(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/ReplacementStampAllocate/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getStampReplacementInvoiceHeader(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/InvoiceHeader`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}
export async function getStampReplacementInvoiceDetails(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/InvoiceDetails/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function updateStampReplacementHeader(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/CommitOrderHeader`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function updateStampReplacementItem(data: { [key: string]: any }) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/CommitOrderItem`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteStampReplacement(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${stampsEndpoint}/queries/DeleteAllocation/${data.id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getStampsEstimates(local: any, order: any) {
  const body = {
    localNum: local,
    orderId: parseInt(order),
    viewOnly: true,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/getStampEstimates`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateStampEstimate(
  local: string,
  order: string,
  quantity: string,
  stamp: { [key: string]: any },
  range: string
) {
  const body = {
    localNum: local,
    orderId: parseInt(order),
    itemId: parseInt(stamp.itemId),
    quantity: parseInt(quantity),
    unitPrice: parseFloat(stamp.unitPrice),
    stampRangeStart: parseInt(range),
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/AddUpdateItem`
  let response
  await agent
    .put(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Items ▼

export async function getItemsByParams(
  params: object,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false,
  sort: { [key: string]: any } = {}
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/search`
    : `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/search?${
        Object.keys(sort)?.length ? `&$orderBy=${sort.sortBy} ${sort.sortDir.toUpperCase()}` : ''
      }`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function searchItem(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/selectbyid/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function createItem(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/create`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateItem(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/update`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getItemTypes() {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/itemtypes`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getStampTypes() {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/Items/queries/stamptypes`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function getLocalCraftsByParams(
  params: object,
  top: number = 25,
  skip: number = 0,
  noLimit: boolean = false,
  sort: { [key: string]: any } = {}
) {
  const URL = noLimit
    ? `${process.env.REACT_APP_API_URL}${craftEndpoint}/GetLocalCraftsByName`
    : `${process.env.REACT_APP_API_URL}${craftEndpoint}/GetLocalCraftsByName?${
        Object.keys(sort)?.length ? `&$orderBy=${sort.sortBy} ${sort.sortDir.toUpperCase()}` : ''
      }`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function searchLocalCraft(data: { [key: string]: string | number | boolean }) {
  const URL = `${process.env.REACT_APP_API_URL}${craftEndpoint}/GetLocalCraftById/${data.id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })

  return response
}

export async function createLocalCraft(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${craftEndpoint}/InsertLocalCraft`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updateLocalCraft(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}${craftEndpoint}/UpdateLocalCraft`
  let response
  await agent
    .put(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Application ▼

export async function getApplicationFeesItems(local: any, order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetItemByLocalList/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function addApplicationFee(
  local: string,
  order: string,
  itemId: string,
  quantity: string,
  comments: string
) {
  const body = {
    orderId: parseInt(order),
    localNum: local,
    itemId: parseInt(itemId),
    quantity: parseInt(quantity),
    comments: comments,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/AddOrderItem`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getApplicationFees(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetOrderedItemsList/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteApplicationFee(orderedDetailId: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/DeleteItem/${orderedDetailId}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getOtherFees(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetSelectOrderOtherFeeOrChargeDetail/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function addOtherFee(order: string, description: string, amount: string) {
  const body = {
    orderId: parseInt(order),
    description: description,
    total: parseFloat(amount),
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/InsertOrderOtherFeeOrChargeDetail`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteOtherFee(otherFeeOrChargeId: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/DeleteOtherFeeOrCharge/${otherFeeOrChargeId}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getFees(local: string, order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetOrderedAdditionalItemsList/${order}/${local}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getFeeMembers(local: string, memberId: number, firstName: string, surName: string) {
  const body = {
    localNum: local.toString(),
    memberId: memberId,
    firstName: firstName,
    surName: surName,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/MemberSearchAddAppFee`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function addFeeMember(order: string, membershipId: string) {
  const body = {
    orderId: parseInt(order),
    membershipId: parseInt(membershipId),
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/InsertApplicationFee`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getReinstateMembersFees(local: any, order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetOrderedReinstatementMemberList/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function addReinstateMember(order: string, membershipId: string, reinstatementDate: string) {
  const body = {
    orderId: parseInt(order),
    membershipId: parseInt(membershipId),
    reinstatedDate: reinstatementDate,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/queries/AddReinstatementMember`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function searchReinstateMembers(local: string, memberId: number, firstName: string, surName: string) {
  const body = {
    localNum: local.toString(),
    memberId: memberId,
    firstName: firstName,
    surName: surName,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/MemberSearchPopupReinstateMember`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteReinstateMember(order: string, membershipId: string) {
  const body = {
    orderId: parseInt(order),
    membershipId: parseInt(membershipId),
    message: '',
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/DeleteReinstatementMember`
  let response
  await agent
    .delete(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Credit ▼

export async function getCredits(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/OrderForm/GetOrderCreditAppliedList/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getCreditItems(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetCreditAvailableListByOrder/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function addCredit(order: string, creditOrderId: string, creditType: string) {
  const body = {
    orderId: parseInt(order),
    creditOrderId: parseInt(creditOrderId),
    creditType: creditType,
    amount: 0,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/AddUpdateOrderCredit`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteCredit(order: string, creditId: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/DeleteCredit/${order}/${creditId}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getCreditAvailableListByRegularLocal(local: string) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/ViewOrder/GetCreditAvailableListByRegularLocal/${local}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getCreditAvailableListBySpecialLocal(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/ViewOrders/queries/CreditAvailableListBySpecialLocal`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getCreditAvailableListByAlphanumericLocal(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/ViewOrders/queries/CreditAvailableListByRegularLocal`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getPerCapWaiverCredits(local: any) {
  const body = {
    localNum: local.toString(),
    orderTypeId: 0,
    quarterId: 0,
    memberIdString: '',
    memberSubgroupId: 0,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/GetListPerCapWaiverCredits`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deletePerCapWaiverCredit(id: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/RemovePerCapWaiverCredits/${id}`
  let response
  await agent
    .delete(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updatePerCapWaiverCredit(credit: object) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/ManagePerCapWaiverCredits`
  let response
  await agent
    .post(URL)
    .send(credit)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Credits v2 ▼

export async function getCreditTransactionList(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/GetCreditPurchaseListByOrder`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function purchaseCreditTransaction(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/CreditPurchase`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function updateCreditTransaction(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/UpdateCreditPurchase`
  let response
  await agent
    .put(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function cancelPurchaseCreditTransaction(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/DeleteCreditPurchase`
  let response
  await agent
    .del(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getAvailablePurchasedCredits(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/GetAvailableCreditByLocal`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function getRedeemedCreditsByOrder(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/GetCreditListByOrder`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function redeemCreditsByOrder(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/ReserveCredit`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function cancelRedeemedCreditById(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/CancelCredit`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
export async function cancelRedeemedCreditByOrder(params: object) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_credits_services/CreditTransactions/queries/CancelCreditByOrder`
  let response
  await agent
    .post(URL)
    .send(params)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Orders ▼

export async function addOrder(localNumber: any, orderTypeId = 1, orderCategoryId = 1, memberId: string = '') {
  const body = {
    localNumber,
    orderTypeId,
    orderCategoryId,
    memberId,
    user: '',
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/AddOrder/AddNewOrder`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getOfficers(local: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetLocalOfficerSelect/${local}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function saveCheckedFee(
  local: string,
  order: string,
  additionalItemId: string,
  isSelect: boolean,
  unitPrice: string
) {
  const body = {
    additionalItemId: parseInt(additionalItemId),
    localNum: local.toString(),
    isSelect: isSelect,
    orderId: parseInt(order),
    isUpdateUnitPrice: isSelect,
    unitPrice: !isSelect ? 0 : parseFloat(unitPrice),
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/UpdateAdditionalItemToOrder`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function saveOrder(local: string, order: string) {
  const body = {
    orderId: parseInt(order),
    localNum: local,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/saveorder`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function updatePaymentInfo(
  order: string,
  paymentMethodId: string,
  paymentDate: string,
  paymentNumber: number,
  paidBy: string,
  title: string,
  paymentAmount: string,
  address1: string,
  address2: string,
  customerEmail: string
) {
  const body = {
    orderId: parseInt(order),
    paymentMethodId: parseInt(paymentMethodId),
    paymentDate: paymentDate,
    paymentNumber: paymentNumber || '',
    paidBy: paidBy,
    title: title,
    paymentAmount: parseFloat(paymentAmount) || 0,
    addressLine1: address1,
    addressLine2: address2,
    paymentStatus: 1,
    customerEmail: customerEmail,
  }
  if (body.paidBy === undefined) {
    body.paidBy = ''
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/UpdateOrderPaymentByLocal`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getPaymentInfo(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/ViewOrders/queries/GetOrderPaymentByLocal/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getSavedStatus(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetSavedStatus/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function deleteOrder(order: string, message: string) {
  const body = {
    orderId: parseInt(order),
    message: message,
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/DeleteOrder`
  let response
  await agent
    .delete(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getOrderStatus(order: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetOrderStatus/${order}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function confirmOrder(order: string) {
  const body = {
    orderId: parseInt(order),
    newOrderStatus: 2,
    user: '',
    message: '',
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/queries/SubmitOrderByLocal`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function processOrderPayment(order: string) {
  const body = {
    orderId: parseInt(order),
    newOrderStatus: 3,
    user: '',
    message: '',
  }
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/queries/SubmitOrderByLocal`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getOnlinePaymentLink(
  order: string,
  quantity: string,
  amount: string,
  token: string,
  local: string
) {
  const body = {
    orderId: order,
    productName: `Order No. ${order}`,
    quantity: parseInt(quantity),
    amount: parseFloat(amount) * 100,
    redirectTo: `${process.env.REACT_APP_SITE_URL}orders/order-paid?order=${order}&local=${local}`,
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/Payments/GetPaymentLink`
  let response
  await agent
    .post(URL)
    .set('Authorization', `bearer ${token}`)
    .send(body)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getLocalIdByLocalNumber(localNum: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetLocalIdByLocalNumber/${localNum}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getOnlinePaymentLinkCanada(
  order: string,
  quantity: string,
  amount: string,
  token: string,
  local: string,
  email: string
) {
  const body = {
    orderId: order,
    productName: `Invoice for Order No. ${order}, Local No. ${local}`,
    quantity: quantity,
    amount: parseInt(amount) * 100,
    customerEmail: email,
  }
  const URL = `${process.env.REACT_APP_API_URL}iatse_orders_services/Payments/CreateInvoice`
  let response
  await agent
    .post(URL)
    .set('Authorization', `bearer ${token}`)
    .send(body)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

// ▼ Transaction Orders ▼

export async function addNewTransactionCheckOrder(
  TransactionTypeId: string,
  SpecialTypeId: string,
  LocalId: string,
  MembershipIdString: string,
  PaymentMethodId: string,
  DepositDate: string,
  PaymentNumber: string,
  PaidBy: string,
  Title: string,
  PaymentAmount: string,
  AddressLine1: string,
  AddressLine2: string,
  PaymenStatus: string,
  Comments: string,
  User: string,
  PayeeDescription: string
) {
  const body = {
    TransactionTypeId: parseInt(TransactionTypeId),
    SpecialTypeId: parseInt(SpecialTypeId),
    LocalId: parseInt(LocalId),
    MembershipIdString: MembershipIdString,
    PaymentMethodId: parseInt(PaymentMethodId),
    DepositDate: DepositDate,
    PaymentNumber: PaymentNumber,
    PaidBy: PaidBy,
    Title: Title,
    PaymentAmount: parseFloat(PaymentAmount),
    AddressLine1: AddressLine1,
    AddressLine2: AddressLine2,
    PaymenStatus: PaymenStatus,
    Comments: Comments,
    User: User,
    PayeeDescription: PayeeDescription,
  }
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/AddOrder/AddNewTransactionCheckOrder`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function saveTransactionCheckHeader(
  order_id: any,
  transaction_type_id: any,
  payee_description: string,
  account_id: any
) {
  const body = {
    OrderId: order_id,
    TransactionTypeId: transaction_type_id,
    PayeeDescription: payee_description,
    AccountId: account_id,
  }
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/orderForm/SaveTransactionCheckHeader`
  let response
  await agent
    .post(URL)
    .send(body)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getTransactionForm(orderId: string) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/orderForm/GetTransactionCheckHeaderKeys/${orderId}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getTransactionTypes() {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/OrderSearch/getItems`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function OrderCalculateTotalByLocal(order_id: string) {
  const URL = `${process.env.REACT_APP_API_URL}${naiveEndpoint}/ViewOrders/queries/GetOrderCalculateTotalByLocal/${order_id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getMembershipIdByOrderID(order_id: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/orderForm/GetMembershipIdByOrderID/${order_id}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function getLocalNumberByOrderID(orderId: string) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/ViewOrders/queries/GetLocalNumberByOrderID/${orderId}`
  let response
  await agent
    .get(URL)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}

export async function InsertOrderOtherFeeExpelled(data: object) {
  const URL = `${process.env.REACT_APP_API_URL}IATSE_orders_Services/OrderForm/InsertOrderOtherFeeExpelled`
  let response
  await agent
    .post(URL)
    .send(data)
    .set('Authorization', 'Bearer ' + cookie)
    .then((res: Response) => {
      response = { ...res, data: res?.body }
    })
    .catch((error: any) => {
      response = { ...error.response, data: error.response?.body }
    })
  return response
}
