import React, { useEffect, useRef, useState } from 'react';
import { Text, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from '../context/auth/Auth';


export default function InactivityLogoutTimer() {

    const queryClient = useQueryClient()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const initialRef = useRef()
    const finalRef = useRef()
    const auth = React.useContext<any>(AuthContext)

    const checkForInactivity = () => {
        const expireTimeString = localStorage.getItem('expireTime');
        const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

        const halfwayTimeString = localStorage.getItem('halfwayTime');
        const halfwayTime = halfwayTimeString ? parseInt(halfwayTimeString) : 0;

        //Halfway to logout time
        if (Date.now() > halfwayTime && expireTime != 0) {
            localStorage.setItem('modalOpen', 'true');
            setIsModalOpen(true)
        }

        //If Logout interval is reached
        if (Date.now() - expireTime > 0 && expireTime != 0) {
            localStorage.removeItem('expireTime');
            localStorage.removeItem('halfwayTime');
            queryClient.clear()
            auth.actions.logout()
        }
    };

    const updateExpiryTime = () => {
        if (localStorage.getItem('modalOpen') !== "true") {
            const expireTime = Date.now() + 15 * 60 * 1000; // 15 minutes in milliseconds
            const halfwayTime = Date.now() + 10 * 60 * 1000 // 10 minutes in milliseconds
            const dateReadable = new Date(expireTime)

            localStorage.setItem('modalOpen', 'false');
            localStorage.setItem('expireTimeReadable', dateReadable.toLocaleTimeString())
            localStorage.setItem('expireTime', expireTime.toString());
            localStorage.setItem('halfwayTime', halfwayTime.toString());
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 5000); // Check for inactivity every 5 seconds

        return () => clearInterval(interval);
    }, []);

    //Handle interaction checking
    useEffect(() => {
        const events = ['click', 'keypress', 'scroll', 'mousemove'];

        const update = () => {
            updateExpiryTime();
        };

        events.forEach(event => window.addEventListener(event, update));

        return () => {
            events.forEach(event => window.removeEventListener(event, update));
        };
    }, []);


    return (
        <>
            <Modal
                size="xl"
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false)
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{`Are you still there?`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Flex justifyContent="space-between">
                            <Text>{`Due to inactivity you will be logged out at ${localStorage.getItem('expireTimeReadable')}`}</Text>
                        </Flex>
                    </ModalBody>

                    <form>
                        <ModalFooter pb="5" alignItems="flex-end" justifyContent="space-between">
                            <Flex flex="1" justifyContent="flex-end" alignItems="flex-end">
                                <Button
                                    fontWeight="400"
                                    size="sm"
                                    w="100px"
                                    colorScheme="brand"
                                    mr={3}
                                    onClick={() => {
                                        updateExpiryTime()
                                        setIsModalOpen(false)
                                    }}
                                >
                                    Stay Signed In
                                </Button>
                                <Button
                                    fontWeight="400"
                                    size="sm"
                                    w="100px"
                                    onClick={() => {
                                        localStorage.removeItem('expireTime');
                                        localStorage.removeItem('halfwayTime');
                                        localStorage.removeItem('expireTimeReadable');
                                        localStorage.removeItem('modalOpen');
                                        queryClient.clear()
                                        auth.actions.logout()
                                        setIsModalOpen(false)
                                    }}
                                >
                                    Log Out
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}